var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mainBox", { attrs: { mClass: "organizBox" } }, [
    _c(
      "div",
      { staticClass: "wapBox" },
      [
        _c("div", { staticClass: "flexs left" }, [
          _c("div", { staticClass: "l_title" }, [
            _c("div", { staticClass: "w50" }, [_vm._v("组织架构")]),
            _c(
              "div",
              { staticClass: "w50 evts" },
              [
                _c(
                  "el-dropdown",
                  { attrs: { trigger: "click" } },
                  [
                    _c("span", { staticClass: "el-dropdown-link" }, [
                      _c("span", { staticClass: "i_add" }, [_vm._v("+")]),
                      _vm._v(" 添加公司/部门\n\t\t\t\t\t  \t"),
                    ]),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c(
                          "el-dropdown-item",
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "drBtn",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toAddCompany()
                                  },
                                },
                              },
                              [_vm._v("添加公司")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-dropdown-item",
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "drBtn",
                                attrs: {
                                  type: "text",
                                  disabled: _vm.treeData.length < 1,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toAddDept()
                                  },
                                },
                              },
                              [_vm._v("添加部门")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "treeWarp" }, [
            _c(
              "div",
              { staticClass: "pd16" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入" },
                  model: {
                    value: _vm.filterText,
                    callback: function ($$v) {
                      _vm.filterText = $$v
                    },
                    expression: "filterText",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "scrollBox" },
              [
                _c("el-tree", {
                  ref: "myTree",
                  attrs: {
                    data: _vm.treeData,
                    "filter-node-method": _vm.filterNode,
                    "expand-on-click-node": false,
                    props: _vm.treeProps,
                  },
                  on: { "node-click": _vm.nodeClick },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ node, data }) {
                        return _c(
                          "div",
                          {
                            class: {
                              txBlue:
                                _vm.actvedNode && data.deptId == _vm.initTbPid,
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(data[_vm.treeProps.label]) +
                                "\n\t\t\t\t\t\t\t"
                            ),
                            _c("i", {
                              staticClass: "el-icon-edit-outline _edicon",
                              on: {
                                click: function ($event) {
                                  return _vm.editCompanyDept(data)
                                },
                              },
                            }),
                            _c("i", {
                              staticClass: "el-icon-delete _delicon",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delCompanyDept1(data)
                                },
                              },
                            }),
                          ]
                        )
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "flexs right" }, [
          _vm.actvedNode
            ? _c("div", { staticClass: "p_name" }, [
                _vm._v(_vm._s(_vm.actvedNode[_vm.treeProps.label])),
              ])
            : _c("div", { staticClass: "p_name" }, [_vm._v("-")]),
          _c(
            "div",
            { staticClass: "eBtns" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 14 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "b_it",
                          attrs: {
                            size: "small",
                            type: "primary",
                            disabled: !_vm.initTbPid,
                            icon: "el-icon-plus",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toAddPeople()
                            },
                          },
                        },
                        [_vm._v("添加人员")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.tbRowDel()
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", plain: "" },
                          on: { click: _vm.downEmpTemplate },
                        },
                        [_vm._v("导入模版")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", plain: "" },
                          on: {
                            click: function ($event) {
                              _vm.uploadModel = true
                            },
                          },
                        },
                        [_vm._v("导入")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", plain: "" },
                          on: { click: _vm.exportEmp },
                        },
                        [_vm._v("导出")]
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "filter-container",
                        attrs: { align: "right" },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: _vm.$t("commons.searchPhrase"),
                            clearable: "",
                            size: "small",
                          },
                          model: {
                            value: _vm.searchPhrase,
                            callback: function ($$v) {
                              _vm.searchPhrase = $$v
                            },
                            expression: "searchPhrase",
                          },
                        }),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "small",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.initTable },
                          },
                          [_vm._v("搜索")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tbBox" },
            [
              _c(
                "div",
                { staticClass: "table-body" },
                [
                  _c("TTable", {
                    ref: "myRefTable",
                    attrs: {
                      checkBox: "",
                      tbloading: _vm.tbloading,
                      tableData: _vm.tableData,
                      tableTitle: _vm.tableTitle,
                      tbParams: _vm.tableParams,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "operating",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.toAddPeople(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.tbRowDel(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("TPagination", {
                on: { initTable: _vm.initTable },
                model: {
                  value: _vm.tableParams,
                  callback: function ($$v) {
                    _vm.tableParams = $$v
                  },
                  expression: "tableParams",
                },
              }),
            ],
            1
          ),
        ]),
        _c("AddCompany", {
          attrs: { isOpen: _vm.compInfo.open, dataId: _vm.compInfo.dataId },
          on: { close: _vm.closeAddCompany },
        }),
        _c("AddDept", {
          attrs: {
            isOpen: _vm.deptInfo.open,
            dataId: _vm.deptInfo.dataId,
            parentData: _vm.actvedNode,
          },
          on: { close: _vm.closeDeptModel },
        }),
        _vm.peoInfo.open
          ? _c("AddPeople", {
              attrs: {
                isOpen: _vm.peoInfo.open,
                parentData: _vm.actvedNode,
                dataId: _vm.peoInfo.dataId,
                opeartData: _vm.rowDatas,
              },
              on: { close: _vm.closePeoModel },
            })
          : _vm._e(),
        _c(
          "el-dialog",
          {
            attrs: { visible: _vm.uploadModel, width: "40%" },
            on: {
              "update:visible": function ($event) {
                _vm.uploadModel = $event
              },
            },
          },
          [
            _c(
              "template",
              { slot: "title" },
              [_c("title-icon"), _vm._v("上传文件")],
              1
            ),
            _c(
              "div",
              { attrs: { slot: "footer" }, slot: "footer" },
              [
                _c("upload", {
                  attrs: {
                    ulType: _vm.uploadType,
                    excelType: _vm.excelType,
                    rootPath: _vm.rootPath,
                  },
                  on: { success: _vm.uploadSuccess, fail: _vm.uploadFail },
                }),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
	<div class="Y-tips Addpple">
		<el-dialog class='diyModel' :title="dataId?'编辑人员':'添加人员'" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="528px" :before-close="closeMypup">
			<div >
				<TForm  ref="htmlFrom" lableRow :autoCheck='true' labelPosition='left' :model="htmlDatas" :formlist="formlist" label-width='88px' :rowGutter='16'>
					<template slot="queryAccounts" slot-scope="scope">
						<div v-if="!dataId">
							<account-select  v-model="htmlDatas[scope.current.keys]" valueField="userId" @change="changeChecked"></account-select>
						</div>
						<el-input v-else v-model="htmlDatas.userAccount " readonly></el-input>
					</template>
					<template slot="checkRegion" slot-scope="scope">
						<el-cascader  v-model="htmlDatas[scope.current.keys]" placeholder="请选择" :options="orgCascadeList" filterable change-on-select style='width: 100%;'></el-cascader>
					</template>
				</TForm>
				<div class="btmBtn txRight" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary" @click='validateFroms' :disabled='isHttping'> 确认</el-button>
		        </div>
			</div>
		</el-dialog>
	</div>
</template>


<script>
	import TForm from '@/components/YTable/TForm.vue';
	import {orgCascade} from '@/api/ruge/organization/tenant/organization'
	import AccountSelect from '@/components/AccountSelect'
	import {insertEmployee,updateEmployee,isBindUser} from '@/api/ruge/organization/tenant/employee'
	import { envInfo } from '@/constants/envInfo'
	export default {
		name: 'AddPeople',
		components: {TForm,AccountSelect},
		props: {
			dataId: {
				type: String|Number,
				default: '',
			},
			parentData:{
				type: Object,
				default: ()=>{
					return {
						deptId:-1,
						companyId:'',
					}
				},
			},
			opeartData:{
				type: Object,
				default: ()=>{
					return {
						userAccount:'',
						deptId:-1,
						companyId:'',
					}
				},
			},
		},
		watch:{
			dataId: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.getDatasById();
					this.$nextTick(() => {
						this.$refs.htmlFrom.resetFields();
					})
				}
			},
			opeartData: {
				deep: true, // 深度监听
				handler(row, oval) {
					this.resetUserInfo();					
				}
			},
		},
		data() {
			return {
				htmlDatas:{
					userAccount:'',
  			},
  			isHttping:false,
  			formlist:[
  				{name: '账号 ',keys:'userId',value:[],type:'slot',isMust:true,slotName:"queryAccounts"},
  				{name: '姓名',keys: 'userName',value: '',type: 'input',disabled:true},
  				{name: '手机',keys: 'phone',value: '',type: 'input',disabled:true},
  				{name: '邮箱',keys: 'email',value: '',type: 'input',disabled:true},
					{name: '组织',keys:'organiza',value:'',type:'slot',slotName:"checkRegion"},
  			],
  			orgCascadeList:[],
			}
		},
		mounted() {
			this.resetUserInfo();
			this.initOrganizaArrs();
		},
		methods: {
			resetUserInfo(){
				if(!this.dataId){
					//新增时候选中组织数据的逻辑
					let arr1 = [this.parentData.companyId];
		      if (this.parentData.parentId != -999) arr1.push(this.parentData.deptId);
					this.htmlDatas.organiza=arr1;
					console.log('organiza222',this.htmlDatas)
					return;
				};
				let row=JSON.parse(JSON.stringify(this.opeartData));
	      let arr = [row.companyId];
	      if (row.deptId != null)arr.push(row.deptId);
	      if (row.dutyId != null) arr.push(row.dutyId);
	      this.htmlDatas.organiza=arr;
	      console.log('organiza',this.htmlDatas)
	      this.htmlDatas={...this.htmlDatas,...row};
			},
			initOrganizaArrs(){
				orgCascade().then(cascadeList => {
	          cascadeList.forEach(function (obj) {
	              obj.label = obj.companyName;
	              obj.value = obj.companyId;
	              if (obj.children.length != 0) {
	                  obj.children.forEach(function (data) {
	                      data.label = data.deptName;
	                      data.value = data.deptId;
	                      if (data.children.length != 0) {
	                          data.children.forEach(function (msg) {
	                              msg.label = msg.dutyName;
	                              msg.value = msg.dutyId;
	                          });
	                      } else {
	                          data.children = undefined;
	                      }
	                  });
	              } else {
	                  obj.children = undefined;
	              }
	          });
	          this.orgCascadeList = cascadeList;
	      })
				
			},
			changeChecked(selected){
        isBindUser({"userAccount":selected.userAccount}).then((res) => {
            if(res == 0){
//            this.htmlDatas.photo = envInfo.bgApp.ssoPath + "/param/avatar?u=" + selected.userAccount;
              this.htmlDatas={...this.htmlDatas,...selected};
              
              console.log('htmlDatas',this.htmlDatas)
            }else{
              this.resetTFrom();
              this.$message({
                message: selected.userAccount+this.$t('organization.employee.prompt'),
                type: 'info'
              });
            }
        })
			},
			async getDatasById(){
//					let dataObj=this.parentData;
//					if(this.dataId){
//						dataObj=this.opeartData;
//					}
//					const {companyId,deptId,dutyId,userId}=dataObj;
//					let oArr=[companyId];
//					if(deptId&&deptId>-1){
//						oArr.push(deptId);
//						if(dutyId) oArr.push(dutyId);
//					}
//					this.htmlDatas.organiza=arr1;
//				if(this.dataId){
//	  			let res = await this.ApiHttp('/organization/tenant/dept/getId?deptId='+this.dataId);
//					if(res){
//						this.htmlDatas={...this.htmlDatas,...res};
//					}
//				}else{
//					this.resetTFrom();	//没有ID 代表新增，重置输入框
//				}
			},
			closeMypup(){
				this.resetTFrom();
				this.$emit('close')
			},
			resetTFrom(){
  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
  		},
  		validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
				})
			},
			async getFromDatas(){
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				let saveType='create';
				if(this.dataId){
					saveType='update';
				}
				const {userAccount,userId,organiza}=this.htmlDatas;
				const [companyId,deptId,dutyId]=organiza;
				let params={companyId,deptId,dutyId,userId,userAccount}
				let res = await this.ApiHttp('/organization/tenant/employee/'+saveType,params,'post');
				if(res){
					if(res.result&&res.result=="fail"){
						this.$message('操作失败');
						return;
					}
					this.$refs.htmlFrom.resetFields();
					this.$message({type: 'success',message: this.$t('message.saveSuccess')});
					this.$emit('close','init')
				}
			},
			
		}
	}
</script>

<style >
	.Addpple {
		.el-input.is-disabled .el-input__inner{
			background-color:white;
			color: #606266 !important;
		}
	}
	
</style>
<template>
	<!--组织架构-->
	<mainBox mClass='organizBox'>
		<div class="wapBox">
			<div class="flexs left">
				<div class="l_title">
					<div class="w50">组织架构</div>
					<div class="w50 evts" >
						<el-dropdown trigger="click">
						  	<span class="el-dropdown-link">
						   		<span class="i_add">+</span> 添加公司/部门
						  	</span>
						  	<el-dropdown-menu slot="dropdown">
							    <el-dropdown-item>
							    	<el-button class='drBtn' type="text" @click='toAddCompany()'>添加公司</el-button>
							    </el-dropdown-item>
							    <el-dropdown-item>
							    	<el-button class='drBtn' type="text" :disabled='treeData.length<1' @click='toAddDept()'>添加部门</el-button>
							    </el-dropdown-item>
						  	</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
				<div class="treeWarp">
					<div class="pd16">
						<el-input placeholder="请输入" v-model="filterText"></el-input>
					</div>
					<div class="scrollBox">
						<el-tree ref='myTree' :data="treeData" :filter-node-method="filterNode" :expand-on-click-node='false' :props="treeProps" @node-click='nodeClick' >
							<div :class="{txBlue:actvedNode&&data.deptId==initTbPid}" slot-scope="{ node, data }">
								{{data[treeProps.label]}}
								<i @click="editCompanyDept(data)" class="el-icon-edit-outline _edicon"></i>
								<i @click.stop="delCompanyDept1(data)" class="el-icon-delete _delicon"></i>
							</div>
						</el-tree>
					</div>
				</div>
			</div>
			<div class="flexs right">
				<div class="p_name" v-if="actvedNode">{{actvedNode[treeProps.label]}}</div>
				<div class="p_name" v-else>-</div>
				<div class="eBtns">
					<el-row>
			      <el-col :span="14">
			      	<el-button class='b_it' size="small" @click='toAddPeople()' type="primary" :disabled='!initTbPid' icon='el-icon-plus' >添加人员</el-button>
		         	<el-button  size="small" plain @click='tbRowDel()'>删除</el-button><!-- 导出 -->
		         	<el-button  size="small" plain @click='downEmpTemplate'>导入模版</el-button><!-- 导出 -->
			        <el-button  size="small" plain @click="uploadModel = true">导入</el-button><!-- 导出 -->
			        <el-button  size="small" plain @click='exportEmp'>导出</el-button><!-- 导出 -->
			      </el-col>
			      <el-col :span="10" >
			        <div class="filter-container" align="right">
			          <el-input :placeholder="$t('commons.searchPhrase')" v-model='searchPhrase' clearable size="small" style="width: 200px"/>
			          <el-button size="small" type="primary" icon="el-icon-search" @click='initTable'>搜索</el-button>
			        </div>
			      </el-col>
			    </el-row>
				</div>
				<div class="tbBox ">
					<div class="table-body">
					<TTable ref="myRefTable" checkBox :tbloading='tbloading'  :tableData="tableData"  :tableTitle="tableTitle" :tbParams="tableParams">
						<template slot='operating' slot-scope="scope">
							<el-button type="text" @click.stop="toAddPeople(scope.row)" >编辑</el-button>
							<el-button type="text" @click.stop="tbRowDel(scope.row)" >删除</el-button>
						</template>
					</TTable>
				</div>
				<TPagination v-model="tableParams" @initTable="initTable" ></TPagination><!-- 分页 -->
				</div>
			</div>
			<AddCompany  :isOpen='compInfo.open' @close='closeAddCompany' :dataId='compInfo.dataId' />
			<AddDept  :isOpen='deptInfo.open' @close='closeDeptModel' :dataId='deptInfo.dataId' :parentData='actvedNode' />
			<AddPeople v-if='peoInfo.open' :isOpen='peoInfo.open' @close='closePeoModel' :parentData='actvedNode' :dataId='peoInfo.dataId' :opeartData='rowDatas'/>
			<!--导入文件-->
	    <el-dialog :visible.sync="uploadModel" width="40%">
	      <template slot="title"><title-icon />上传文件</template>
	      <div slot="footer">
	        <upload
	          :ulType="uploadType"
	          :excelType="excelType"
	          :rootPath="rootPath"
	          @success="uploadSuccess"
	          @fail="uploadFail"
	        ></upload>
	      </div>
	    </el-dialog>
		</div>
	</mainBox>
</template>
<script>
	import pagination from "@/mixins/TTable/pagination";
	import seachAndTable from "@/mixins/TTable/seachAndTable";
	import TTable from '@/components/YTable/TTable.vue';
	import TPagination from '@/components/YTable/TPagination.vue';
	import AddCompany from './model/AddCompany.vue'
	import AddDept from './model/AddDept.vue'
	import AddPeople from './model/AddPeople.vue'
	import { envInfo } from "@/constants/envInfo";
	import Upload from "@/components/Upload";
	import { exportEmployee } from "@/api/ruge/tenant/employee";
	import {downEmployeeTemplate,deleteEmployee,batchDeleteEmployee,} from "@/api/ruge/tenant/organization";
	export default {
		mixins: [pagination,seachAndTable],
		components: {TTable,TPagination,AddCompany,AddDept,AddPeople,Upload},
		data() {
			return {
				uploadModel:false,
				uploadType: "ExcelImport",
	      excelType: "ruge.employeeTenant",
	      rootPath: envInfo.bgApp.organizationPath,
				filterText: '',
				treeData:[],
				initTbPid:0,//查询table数据需要的项目id
				treeProps:{
					label: 'name',
					children: 'children',
				},
				tableTitle:[
					{name:'账号',prop:"userAccount"},
					{name:'姓名',prop:"userName"},
					{name:'手机',prop:"phone"},
					{name:'邮箱',prop:"email"},
					{name:'岗位',prop:"dutyName"},
			    {name:'操作',width:"180",fixed:'right',slotName:"operating",},
				],
				tableData:[],
				compInfo:{//新增，编辑公司的弹框信息
      		open:false,
      		dataId:'',
      	},
      	deptInfo:{
      		open:false,
      		dataId:'',
      	},
      	peoInfo:{
      		open:false,
      		dataId:'',
      	},
      	
      	typeInfo:{
      		open:false,
      		dataId:'',
      		dataName:'',
      	},
      	addInfo:{//新增，编辑位置的弹框信息
      		open:false,
      		dataId:'',
      	},
      	actvedNode:{
      		parentName:''
      	},//选中的项目节点  用于查询table数据
      	operateRow:{},
      	isTbAll:false,
      	searchPhrase:'',
      	rowDatas:{},//编辑人员时候操作的行数据
			};
		},
		created() {},
		mounted() {
			this.initTreeDatas();
		},
		watch: {
			filterText(val) {
				this.$refs.myTree.filter(val);
			}
		},
		computed: {},
		methods: {
			exportEmp() {
				const params = {
					searchPhrase:this.searchPhrase,
      		...this.seachData,
					...this.tableParams,
      	};
      	console.log('23232',params)
	      exportEmployee(params)
	        .then((msg) => {
	          this.$message({
	            type: "success",
	            message: this.$t("message.operationSuccess"),
	          });
	          let exportObj = {
	            taskId: msg,
	            taskName: "Employee",
	            taskStatus: 0,
	            rootPath: "organizationPath",
	          };
	          //将导出任务丢入导出任务列表中
	          store.dispatch("PushExportNotice", exportObj);
	        })
	        .finally(() => {
	          this.listLoading = false;
	        });
	    },
			downEmpTemplate() {//导入模版
	      downEmployeeTemplate();
	    },
	    uploadSuccess() {
	      this.uploadModel = false;
	      // this.$message({type: 'success', message: this.$t('message.uploadSuccess')});
    	},
	    uploadFail() {
	      this.$message({
	        type: "error",
	        message: this.$t("message.uploadFial"),
	      });
	    },
			editCompanyDept(data){
				if(data.deptId<0){
					this.toAddCompany(data.companyId)
				}else{
					this.toAddDept(data.deptId)
				}
			},
			toAddDept(rowId=''){
				this.deptInfo={
      		open:true,
      		dataId:rowId,
      	};
			},
			closeDeptModel(isInit){
				//关闭项目弹框
				this.deptInfo={
      		open:false,
      		dataId:'',
      	};
      	if(isInit){
      		this.initTreeDatas();
      	}
			},
			
			toAddPeople(row=null){
				let rowId=row?row.empId:'';
				if(row) this.rowDatas={...row};
				this.peoInfo={
      		open:true,
      		dataId:rowId,
      	};
			},
			closePeoModel(isInit){
				this.peoInfo={
      		open:false,
      		dataId:'',
      	};
      	if(isInit){
      		this.initTable();
      	}
				
			},
			//公司相关satrt
			
			toAddCompany(rowId=''){
				console.log('11111',456456)
				//点击打开弹窗
				this.compInfo={
		      		open:true,
		      		dataId:rowId,
		      	};
			},
			closeAddCompany(isInit){
				//关闭项目弹框
				this.compInfo={
      		open:false,
      		dataId:'',
      	};
      	if(isInit){
      		this.initTreeDatas();
      	}
			},
			//公司相关 end
			
			
			nodeClick(node) {
				this.actvedNode = { ...node};
				console.log('this.actvedNode',JSON.parse(JSON.stringify(this.actvedNode)))
				this.initTbPid = node.deptId;
				this.initTable();
			},
			filterNode(value, data) {
				if(!value) return true;
				return data[this.treeProps.label].indexOf(value) !== -1;
			},
			async initTreeDatas(){
				//获取所有有偿服务类型数据
				let params={
					isloading:false
				}
				let res = await this.ApiHttp('/organization/tenant/dept/list/tree',params,'GET');
				let data = this.common.listToTree(res, "deptId", "parentId");
				this.treeData=data||[];
			},
			
			async initTable(){
				if(!this.initTbPid) return;
				this.tbloading=true;
				let params={
					isloading:false,
					deptId:this.initTbPid,
					searchPhrase:this.searchPhrase,
					current: this.tableParams.current,
					rowCount: this.tableParams.rowCount,
				}
				if(this.initTbPid<1){
					
					delete params.deptId;
					params.companyId=this.actvedNode.companyId;
				}
				let res = await this.ApiHttp('/organization/tenant/employee/empPage',params);
				this.isTbAll=false;
				console.log('55555555',res)
				res.rows.map(it=>{
					if(it.price==0){
						it.price='0'
					}
				})
				this.tableData = res.rows||[];
				this.tableParams.total = res.total;
				this.$nextTick(()=>{
	  				this.tbloading=false;
	  			})
			},
			
			
			tbRowAddOrEdit(row,pid=''){
				
				this.operateRow={...row};
				console.log('445554',this.operateRow)
				this.addInfo={
					dataId:pid,
					open:true,
				}
			},
			toAddTbRow(){
				//点击打开弹窗
				let data={
					positionId:'',
					positionName:"",
				}
//				if(this.actvedNode) data.positionName=this.actvedNode.projectName;//获取上级名称
				this.tbRowAddOrEdit(data);
			},
			tbRowEdit(row){
				this.tbRowAddOrEdit(row)
			},
			tbRowDel(row){
				let params={
					userIds: '',
				}
				if(row){
					params.userIds=row.userId;
				}else{
					let Arrs=this.$refs.myRefTable.selection;
					if(Arrs){
						let ids=[];
						Arrs.map(it=>ids.push(it.userId));
						params.userIds=ids.join(",");
					}
				}
				if(!params.userIds){
					this.$message('请选择数据')
				 	return;
				}
				console.log('tbRowDel-params',params)
				this.$confirm('是否确认删除', '提示').then(() => {
					this.ApiHttp('/organization/tenant/employee/deleteList?userIds='+params.userIds,params,'delete').then(res=>{
						if(res){
							this.initTable();
						}
					});
					
				}).catch(() => {});
			},
			closeAddPosition(isInit){
				//关闭项目弹框
				this.addInfo={
		      		open:false,
		      		dataId:'',
		      	};
		      	if(isInit){
		      		this.initTable();
		      	}
			},
			
			toAddServeTypes(datas){
				//点击打开弹窗新增或修改服务类型
				console.log('11111',456456)
				this.typeInfo={
	      		open:true,
	      		dataId:datas?datas.serviceTypeId:'',
	      		dataName:datas?datas.serviceTypeName:'',
	      	};
			},
			toDelServeTypes(datas){
				
				this.$confirm('是否确认删除', '提示').then(() => {
					let params={
					 serviceTypeId:datas.serviceTypeId,
					}
					this.ApiHttp('/base/tenant/paidService/deleteServiceType',params,'delete').then(res=>{
						this.initTreeDatas();
					});
				}).catch(() => {});
				
			},
			
			
			delCompanyDept1(data){
				//删除单个公司或者部门
				if(data.deptId<0){
					this.toDelCompany(data)
				}else{
					this.toDelDept(data)
				}
			},
			toDelCompany(datas){
				this.$confirm('是否确认删除该公司', '提示').then(() => {
					this.ApiHttp('/organization/tenant/company/delete/'+datas.companyId,{},'delete').then(res=>{
						this.initTreeDatas();
					});
				}).catch(() => {});
				
			},
			toDelDept(datas){
				this.$confirm('是否确认删除该部门', '提示').then(() => {
					this.ApiHttp('/organization/tenant/dept/delete/'+datas.deptId,{},'delete').then(res=>{
						this.initTreeDatas();
					});
				}).catch(() => {});
				
			},
			//公司相关 end
		},
	};
</script>

<style scoped lang="scss">

	.wapBox{
		height: 100%;
		display: flex;
		
		.flexs{
			height: 100%;
		}
		.left{
			width: 320px;
			border-right: 1px solid #EEEEEE;
		}
		.l_title{
			padding: 12px 0;
			border-bottom: 1px solid #EBEEF5;
			color: #2F3941;
			display: flex;
			.w50{
				font-weight: 600;
				width: 50%;
				padding: 0 16px;
				line-height: 36px;
			}
			.evts{
				cursor: pointer;
				font-weight: 400;
				text-align: right;
				color: #4E5969;
			}
		}
		.treeWarp{
			padding: 12px 16px;
			box-sizing: border-box;
			height: calc(100% - 62px);
    	overflow-y: auto;
		}
		.right{
			width: calc(100% - 320px);
			padding:16px 24px;
			.c_name{
				padding: 10px 0;
				font-size: 14px;
				color: #8993A4;
			}
			.p_name{
				color: #2F3941;
				font-size: 18px;
				font-weight: bold;
				padding: 8px 0 24px 0;
			}
			.eBtns{
				.el-button{
				    padding: 10px 18px;
				}
			}
			.tbBox{
				margin-top: 12px;
				position: relative;
				.diyCkWarp{
					position: absolute;
					top: 20px;
					left: 24px;
				}
			}
		}
		
		.tbTreeWarp{
			margin-top: 4px;
			height: 598px;
			overflow-y: auto;
			
		}
		.tbTreeWarp.tbTreeWarp2{
			height: 540px;
		}
		.diyTbTree{
			width: 100%;
			.treeNode {
			    flex: 1;
			    display: flex;
			    align-items: center;
			    justify-content: space-between;
			    font-size: 14px;
			    padding-right: 8px;
			  }
			.n_name{
				display: inline-block;
				width: calc(100% - 160px);
			}
			.n_btns{
				display: inline-block;
				width: 160px;
			}
			.treeNode{
				
			}
		}
	}
</style>
<style type="text/css">
	.treeWarp {
		.el-tree-node__content {
			height: 40px;
			width: 100%;
			position: relative;
			._edicon,._delicon{
    			cursor: pointer;
    			position: absolute;
    			right: 6px;
    			font-size: 18px;
    			top: 8px;
    			display: none;
    		}
    		._delicon{
    			position: absolute;
    			right: 26px;
    		}
		}
		.el-tree-node__content:hover{
			._edicon,._delicon{
				display: block;
			}
		}
	}
	.tbTreeCheck thead .el-checkbox{
		display: none;
	}
	.organizBox .m-body{
		padding: 0 !important;
	}
	.diyTbTree{
		.el-tree-node__content{
		    height: 48px;
			border-bottom: 1px solid #EBEEF5;
		}
	}
	.treeWarp{
		.el-tree-node__content{
		    height: 40px;
		}
	}
	.dfNode1{
		.el-tree-node__label{
			color: #1A4CEC;
		}
	}
	.drBtn.el-button--text{
			color:#2F3941 !important;
		}
</style>
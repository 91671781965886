<template>
	<div class="Y-tips">
		<el-dialog class='diyModel' :title="dataId?'编辑部门':'添加部门'" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="728px" :before-close="closeMypup">
			<div >
				<TForm  ref="htmlFrom" lableRow :autoCheck='true' labelPosition='left' :model="htmlDatas" :formlist="formlist" label-width='88px' :rowGutter='16'>
					<template slot="tagsArrs" slot-scope="scope">
						<div style="display: inline-block;">
							<TagBind :tagValueList="htmlDatas[scope.current.keys]||[]" tagType :notAuto='true' :limit='10' :maxlength='6' addName='添加标签'/>
						</div>
					</template>
					
					<template slot="dutyArrs" slot-scope="scope">
						<el-button class="f_btn addbtn" type="text" @click='addDutyItems' >新增</el-button>
						<div >
							<div class="flexItem" v-for="(it,dex) in htmlDatas[scope.current.keys]" :key='dex'>
								<el-input class="f_inp" v-model='it.dutyName' maxlength="50" show-word-limit></el-input>
								<el-select class="f_sect" v-model='it.dutyPerson'>
									<el-option v-for="item in empArrs" :key="item.userId" :label="item.userName" :value="item.userId"></el-option>
								</el-select>
								<el-button class="f_btn" :disabled='dex<1' type="text" @click='htmlDatas[scope.current.keys].splice(dex,1)'>删除</el-button>
							</div>
							
						</div>
					</template>
					
					<template slot="prevDept" slot-scope="scope">
						<el-input readOnly v-model='htmlDatas.companyDeptName'></el-input>
					</template>
				</TForm>
				<div class="btmBtn" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary" @click='validateFroms' :disabled='isHttping'> 保存</el-button>
		        </div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import pinyin from "js-pinyin";
	import TagBind from "@/components/TagBind";
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		props: {
			dataId: {
				type: String|Number,
				default: '',
			},
			parentData:{
				type: Object,
				default: ()=>{
					return {
						deptName:'',
						deptId:-1,
						companyId:'',
					}
				},
				
			},
		},
		watch:{
			dataId: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.getDatasById();
					this.initEmplistArrs();
					this.$nextTick(() => {
						this.$refs.htmlFrom.resetFields();
					})
				}
			},
			parentData: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.initHeadsArrs();
					let _n=this.parentData.deptName||'';
					if(_n){
						this.htmlDatas.companyDeptName=this.parentData.companyName+' / '+_n;
					}else{
						this.htmlDatas.companyDeptName=this.parentData.companyName;
					}
				}
			},
		},
		components: {TForm,TagBind},
		data() {
			return {
				htmlDatas:{
					dutyList:[],
					companyDeptName:'',
  			},
  			isHttping:false,
  			formlist:[
  				{name: '部门名称',keys: 'deptName',value: '',type: 'input',isMust:true,inputMethod:this.changeCompanyName,maxlen:50},
  				{name: '部门编码',keys: 'deptCode',value: '',type: 'input',isMust:true,trigger: ["blur", "change"]},
  				{name: '负责人',keys: 'deptHeadId',value: '',type: 'select',options:[]},
  				{name: '岗位',keys:'dutyList',value:[],type:'slot',slotName:"dutyArrs"},
					{name: '上一级部门',keys:'prevDept',value:'',type:'slot',slotName:"prevDept"},
  			],
  			empArrs:[],
			}
		},
		
		computed: {},
		created() {
			this.resetTFrom();
		},
		mounted() {},
		updated() {},
		beforeDestroy() {},
		methods: {
			addDutyItems(){
				this.htmlDatas.dutyList.push({dutyName:'', dutyPerson:'',dutyId:0,deptId:this.dataId});
			},
			closeMypup(){
				this.resetTFrom();
				this.$emit('close')
			},
		    changeCompanyName(val) {
		    	// 联动企业名称  生成编码
		      let n = pinyin.getCamelChars(val);
		      this.htmlDatas.deptCode=n;
		    },
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
//	  			this.addDutyItems();
	  		},
			async getDatasById(){
				if(this.dataId){
		  			let res = await this.ApiHttp('/organization/tenant/dept/getId?deptId='+this.dataId);
					if(res){
						let _n='';
						if(res.parentId<0){
							_n=res.companyName;
						}else{
							_n=res.companyName+' / '+res.parentName;
						}
						this.htmlDatas={...this.htmlDatas,...res,companyDeptName:_n};
						res.dutyList=res.dutyList||[];
//						if(res.dutyList.length<1){
//							this.addDutyItems();
//						}
					}
				}else{
					//没有ID 代表新增，重置输入框
					this.resetTFrom()	
				}
			},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
					
				})
//				
			},
			
			async initEmplistArrs(){
				//获取负责人数据
				this.empArrs=[];
				let rqData={
					isloading:false,
					deptId:this.dataId,
				}
				let res = await this.ApiHttp('/organization/tenant/employee/empList',rqData);
				if(res){
					res.map(it=>{
						it.name = it.userName;
						it.code = it.userId;
					})
					this.empArrs=res;
				}
			},
			async initHeadsArrs(){
				//获取负责人数据
				let rqData={
					isloading:false,
					companyId:this.parentData.companyId,
				}
				let res = await this.ApiHttp('/organization/tenant/employee/empList',rqData);
				if(res){
					res.map(it=>{
						it.name = it.userName;
						it.code = it.userId;
					})
					this.common.insertOptions(this.formlist,'deptHeadId',res);//添加选项数据到所属项目
				}
			},
			async getFromDatas(){
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				const dpId=this.parentData.deptId;
				if(!dpId) return;
				let params={
					parentId:dpId<0?-1:dpId,
					companyId:this.parentData.companyId,
					...this.htmlDatas,
				}
				let isAllow=true;
				for(let item of params.dutyList){
					if(!item.dutyName){
						isAllow=false;
						this.$message({message:'岗位名称不能为空',type:'info'})
						break;
					}
				}
				if(!isAllow) return;
				let saveType='create';
				if(this.dataId) saveType='update';
				let res = await this.ApiHttp('/organization/tenant/dept/'+saveType,params,'post');
				if(res){
					this.$refs.htmlFrom.resetFields();
					this.$emit('close','init')
				}
			},
			
			
		},

	}
</script>

<style lang="scss" scoped>
.addbtn{
  position: absolute;
  top: -5px;
  left: 32px;
  font-size: 12px;
}
.flexItem{
	display: flex;
	margin: 10px 0;
	width: 100%;
	.f_inp,.f_sect{
		width: 45%;
		margin-right: 8px;
	}
}
.btmBtn{
	text-align: right;
}

</style>
<template>
	<div class="Y-tips">
		<el-dialog class='diyModel' :title="dataId?'编辑公司':'添加公司'" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="728px" :before-close="closeMypup">
			<div >
				<TForm  ref="htmlFrom" lableRow :autoCheck='true' labelPosition='left' :model="htmlDatas" :formlist="formlist" label-width='88px' :rowGutter='16'>
					<template slot="tagsArrs" slot-scope="scope">
						<div style="display: inline-block;">
							<TagBind :tagValueList="htmlDatas[scope.current.keys]||[]" tagType :notAuto='true' :limit='10' :maxlength='6' addName='添加标签'/>
						</div>
					</template>
				</TForm>
				<div class="btmBtn" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary" @click='validateFroms' :disabled='isHttping'> 保存</el-button>
		        </div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import pinyin from "js-pinyin";
	import TagBind from "@/components/TagBind";
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		props: {
			dataId: {
				type: String,
				default: '',
			},
		},
		components: {TForm,TagBind},
		data() {
			return {
				htmlDatas:{
					projectId:'',
	  				enterpriseTag:[],//标签
	  			},
	  			isHttping:false,
	  			formlist:[
	  				{name: '公司名称',keys: 'companyName',value: '',type: 'input',isMust:true,inputMethod:this.changeCompanyName,maxlen:50},
	  				{name: '公司编码',keys: 'companyCode',value: '',type: 'input',isMust:true,trigger: ["blur", "change"]},
	  				{name: '公司标签',keys:'tagList',value:[],type:'slot',slotName:"tagsArrs"},
	  			],
			}
		},
		
		computed: {},
		watch:{
			dataId: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.getDatasById();
					this.$nextTick(() => {
						this.$refs.htmlFrom.resetFields();
					})
				}
			},
			
		},
		created() {
			this.resetTFrom();
		},
		mounted() {},
		updated() {},
		beforeDestroy() {},
		methods: {
			closeMypup(){
				this.resetTFrom();
				this.$emit('close')
			},
		    changeCompanyName(val) {
		    	// 联动企业名称  生成编码
		      let n = pinyin.getCamelChars(val);
		      this.htmlDatas.companyCode=n;
		    },
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
			async getDatasById(){
				if(this.dataId){
		  			let res = await this.ApiHttp('/organization/tenant/company/getId?companyId='+this.dataId);
					if(res){
						if(res.enterpriseTag){
							res.enterpriseTag=res.enterpriseTag.split(',')
						}else{
							res.enterpriseTag=[]
						}
						this.htmlDatas={...this.htmlDatas,...res}
					}
				}else{
					//没有ID 代表新增，重置输入框
					this.resetTFrom()	
				}
			},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
					
				})
				
//				
			},
			async getFromDatas(){
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				let params={
					...this.htmlDatas,
				}
				let type='create';
				if(this.dataId){
					type='update'
				}
				let res = await this.ApiHttp('/organization/tenant/company/'+type,params,'post');
				if(res){
					this.$refs.htmlFrom.resetFields();
					this.$emit('close','init')
				}
			},
			
			
		},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	text-align: right;
}

</style>